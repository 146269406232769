import { getDatabase, ref, onValue } from "firebase/database";
import _ from "lodash";
import dayjs from "dayjs";

export const useOnlineStatusStore = defineStore("onlineStatus", {
    state: () => {
        return {
            isOnline: true,
            offlineSince: '',
        };
    },
    actions: {
        async init() {
            this.watchOnlineStatus();
        },
        watchOnlineStatus() {
            
            const db = getDatabase();
            const connectedRef = ref(db, ".info/connected");
            const handleChangeStatus_debounce = _.debounce((snap) => {
                if (snap.val() === true) {
                    this.isOnline = true;
                    console.log("connected");
                } else {
                    this.isOnline = false;
                    this.offlineSince = dayjs().format('HH:mm:ss DD/MMM');
                    console.log("not connected");
                }
            }, 2000, {
                'leading': false,
                'trailing': true
            })
            onValue(connectedRef, handleChangeStatus_debounce);
        }

    }
})
